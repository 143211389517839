import { ColorHex } from '#constants';
import { useContrast } from '#hooks';

import type { ThemeUIStyleObject as TSO } from 'theme-ui';
import type { SimpleHeroStyles as Props } from './types';
import { TextureBottom, TextureWidth } from '@lib/constants';

export const useStyles = ({
  backgroundColor,
  texture,
  bkgImagePresent,
}: Props): typeof Styles => {
  const { text } = useContrast(backgroundColor);
  const _backgroundColor = backgroundColor || 'White';
  const _texture = texture || 'No Texture';
  const endMargin = texture === 'No Texture' ? '0px' : '4rem';
  const noTexture = texture === 'No Texture' || bkgImagePresent;

  const Styles = {
    ROOT: {
      contain: 'layout',
      position: 'relative',
      display: 'flex',
      inlineSize: '100%',
      minBlockSize: !noTexture ? '20rem' : [null, '250px'],
      marginBlockEnd: endMargin,
      backgroundColor: `${ColorHex[_backgroundColor]}`,
    } as TSO,

    TEXTURE: {
      margin: '0px',
      position: 'absolute',
      maxInlineSize: TextureWidth[_texture] as string,
      left: '0px',
      insetInlineStart: '0x',
      right: '0px',
      insetInlineEnd: '0px',
      bottom: TextureBottom[_texture] as string,
      insetBlockEnd: TextureBottom[_texture] as string,
      zIndex: 0,
    } as TSO,

    CONTENT: {
      '&, > *': {
        color: bkgImagePresent ? 'white' : text,
      },
      paddingBlockStart: noTexture ? [5, 6] : 7,
      paddingBlockEnd: noTexture ? [5, 6] : 7,
      paddingInlineStart: [4, 1],
      paddingInlineEnd: [4, 1],
      textAlign: 'center',
      inlineSize: 'min(100%, 90rem)',
      margin: 'auto',
    } as TSO,

    CTAS_CONTAINER: {
      marginBlockStart: [5],
      display: 'flex',
      flexDirection: ['column', 'row'],
      justifyContent: 'center',
      rowGap: 4,
      columnGap: 4,
    } as TSO,

    HEADING: {
      fontSize: bkgImagePresent && [5, 7],
      fontWeight: bkgImagePresent && [null, 'extraBold'],
      maxWidth: 9,
      margin: 'auto',
    } as TSO,

    COPY: {
      maxWidth: 10,
      margin: 'auto',
    } as TSO,

    IMAGE: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      zIndex: 'subBkg',
    } as TSO,
  };

  return Styles;
};
