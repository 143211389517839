import { propsValidator } from '@lib/utils';
import { useTexture } from '@lib/hooks';
import { useStyles } from './simplehero.styles';
import { H1, HighlightText } from '#ui';
import ResponsiveMedia from '@components/ResponsiveMedia';
import MarkdownText from '@components/MarkdownText';
import AppButton from '@components/AppButton';
import type { SimpleHeroProps as Props } from './types';
import type { FC } from 'react';

const SimpleHero: FC<Props> = ({
  heading,
  highlightHeading,
  texture,
  backgroundColor,
  responsiveMedia,
  copy,
  ctasCollection,
  primaryCtaColor,
  ...entry
}) => {
  const valid = propsValidator(entry, { heading });

  if (!valid) return null;

  const bkgImagePresent = !!responsiveMedia?.desktopAsset?.url;
  const bkg = bkgImagePresent ? 'Black' : backgroundColor;

  const Styles = useStyles({ backgroundColor, texture, bkgImagePresent });
  const simpleTexture = useTexture(texture);

  const ctas = ctasCollection?.items || [];

  return (
    <header sx={Styles.ROOT}>
      {simpleTexture && !bkgImagePresent && (
        <div sx={Styles.TEXTURE}>{simpleTexture}</div>
      )}
      <div sx={Styles.CONTENT}>
        <H1 sx={Styles.HEADING}>
          {heading}{' '}
          {highlightHeading && (
            <HighlightText bkg={bkg}>{highlightHeading}</HighlightText>
          )}
        </H1>
        {copy && <MarkdownText styles={Styles.COPY}>{copy}</MarkdownText>}
        {Boolean(ctas.length) && (
          <div sx={Styles.CTAS_CONTAINER}>
            {ctas.map((cta) => {
              if (!cta.ctaCopy) return null;

              return (
                <AppButton
                  {...cta}
                  key={`SimpleHero-CTA-${cta.sys.id}`}
                  contrast={backgroundColor}
                  color={primaryCtaColor}
                >
                  {cta.ctaCopy}
                </AppButton>
              );
            })}
          </div>
        )}
      </div>
      {bkgImagePresent && (
        <ResponsiveMedia
          lazy={false}
          sx={Styles.IMAGE}
          {...responsiveMedia}
          fit="cover"
        />
      )}
    </header>
  );
};

export default SimpleHero;
